import React from 'react';

import Card from '@components/card/card.component';
import Layout from '@components/layout/layout.component';
import PageContainer from '@components/page-container/page-container.component';
import ProductModalContent, { FooterButtonComponent } from '@components/product-modal/product-modal-content';
import ProductBreadcrumbs from '@components/product-breadcrumbs/product-breadcrumbs.component';

const ProductTemplate = ({ pageContext: { product }, location }) => {
  return (
    <Layout title={product.slug.toUpperCase()} location={location} seoOptions={{ ogImage: product.images[0] }}>
      <PageContainer>
        <ProductBreadcrumbs product={product} />
        <Card style={{ marginTop: '25px' }}>
          <ProductModalContent product={product} />
          <FooterButtonComponent product={product} style={{
            width: '100%',
            marginTop: '15px',
          }}/>
        </Card>
      </PageContainer>
    </Layout>
  );
};

export default ProductTemplate;
