import styled from 'styled-components';

import Theme, { deviceMediaQ } from '@src/theme';

export default styled.h2`
  font-size: 44px;
  font-weight: 500;
  line-height: 55px;
  color: ${Theme.primaryColor};
  margin-bottom: 15px;
  
  a {
    color: ${Theme.primaryColor};
  }
  
  .highlight {
    font-weight: 800;
    color: ${Theme.secondaryColor};
  }
  
  @media ${deviceMediaQ.mobile} {
    font-size: 7.5vw;
    line-height: unset;
  }
`;
