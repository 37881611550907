import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import Theme from '@src/theme';
import { getProductsUrlByType } from '@src/utils/product.utils';

const TYPE_TRANSLATION_MAP = {
  resonance: 'Ressonância',
  rescue: 'Resgate',
};

const BreadcrumbsStyles = styled.ul`
  display: flex;
  width: 100%;
  margin-top: 25px;
  font-size: 0.8rem;
  
  li {
    &:not(:first-of-type)::before {
      content: '\\003E';
      margin: 0 5px;
    }
    
    a {
      text-transform: uppercase;
      color: ${Theme.primaryColor};
      
      &:hover {
        text-decoration: underline
      }
    }
  }
`;

const ProductBreadcrumbs = ({ product }) => {
  return (
    <BreadcrumbsStyles>
      <li>
        <Link to='/'>Home</Link>
      </li>
      <li>
        <Link to='/produtos/resgate'>Produtos</Link>
      </li>
      <li>
        <Link to={getProductsUrlByType([product.type])}>{TYPE_TRANSLATION_MAP[product.type]}</Link>
      </li>
      <li>
        <Link to='/' disabled>{product.slug}</Link>
      </li>
    </BreadcrumbsStyles>
  );
};

export default ProductBreadcrumbs;
