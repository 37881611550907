import React, { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const ImageComponent = ({ src, ...props }) => {
  const data = useStaticQuery(graphql`
      query {
          images: allFile(
              filter: { internal: { mediaType: { regex: "/image/" } }, extension: { ne: "svg" } }
          ) {
              edges {
                  node {
                      relativePath
                      extension
                      publicURL
                      childImageSharp {
                          gatsbyImageData(layout: CONSTRAINED, quality: 100, formats: [AUTO, WEBP])
                      }
                  }
              }
          }
      }
  `);

  const match = useMemo(
    () => data.images.edges.find(({ node }) => src === node.relativePath),
    [data, src],
  );

  if (!match) {
    return null;
  }

  const { node: { childImageSharp: { gatsbyImageData }, publicURL, extension } = {} } = match;

  if (extension === 'svg' || !gatsbyImageData) {
    return <img src={publicURL} {...props} />;
  }

  return <GatsbyImage image={gatsbyImageData} {...props} />;
};

export default ImageComponent;
