const PRODUCT_TYPE_MAP = {
  resonance: 'ressonancia',
  rescue: 'resgate',
};

const getProductUrl = ({ type, slug }) => `/produtos/${PRODUCT_TYPE_MAP[type]}/${slug}`;
const getProductsUrlByType = (type) => `/produtos/${PRODUCT_TYPE_MAP[type]}`;

module.exports = {
  getProductUrl,
  PRODUCT_TYPE_MAP,
  getProductsUrlByType,
};
