import styled from 'styled-components';

const CarouselStyled = styled.div`
  position: relative;
  width: 100%;

  .chevron {
    background: inherit;
    z-index: 1;
    border: none;
    position: absolute;
    top: 50%;
    padding: inherit;
    cursor: pointer;

    &.right {
      right: 0;
    }

    &.left {
      left: 0;
    }
  }
  
  svg {
    width: 15px;
    opacity: 0.3;
    transition: 0.2s;

    &:hover {
      opacity: 1;
    }
  }
`;

export default CarouselStyled;