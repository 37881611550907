import styled from 'styled-components';
import Theme, { deviceMediaQ } from '@src/theme';

const ProductModalStyled = styled.div`
  p {
    font-size: 0.9rem;
  }

  h3 {
    color: ${Theme.primaryColor};
  }

  .section-title {
    background: ${Theme.primaryColor};
    border-radius: 10px;

    color: ${Theme.lightColor};
    text-align: center;

    padding: 5px 10px;
    margin-top: 15px;
    margin-bottom: 10px;
    min-width: 14px;
    width: fit-content;
    font-size: 0.9rem;
    font-weight: 500;
  }

  section {
    display: flex;
    gap: 20px;

    div {
      text-align: center;
    }

    @media ${deviceMediaQ.mobile} {
      justify-content: center;
    }
  }

  .product-image {
    display: flex;
    justify-content: center;
    height: 25vh;
  }
  
  .lap {
    display: flex;
    gap: 50px;
    text-align: center;
    margin-bottom: 15px;
  }
`;

export default ProductModalStyled;
