import React from 'react';

import Image from '@components/image/image.component';
import ChevronLeft from '@icons/chevron-left.svg';
import ChevronRight from '@icons/chevron-right.svg';
import CarouselStyled from './carousel.styled';

const Carousel = ({ product: { images, slug } }) => {
  const [image, setImage] = React.useState(null);
  const [imageIdx, setImageIdx] = React.useState(0);

  React.useEffect(() => {
    setImage(images[0]);
  }, images);

  const updateProduct = (amount) => {
    let idx = imageIdx + amount;

    if (idx === images.length) idx = 0;
    if (idx === -1) idx = images.length - 1;

    setImageIdx(idx);
    setImage(images[idx]);
  };

  if (!image) {
    return null;
  }

  return (
    <CarouselStyled>
      <button className='chevron left' onClick={() => updateProduct(-1)}>
        <ChevronLeft />
      </button>
      <button className='chevron right' onClick={() => updateProduct(1)}>
        <ChevronRight />
      </button>
      <Image src={`products/${image}`} alt={`Imagem do produto ${slug}`} placeholder='none' className='product-image' objectFit='contain' />
    </CarouselStyled>
  )
};

export default Carousel;