import React from 'react';
import styled from 'styled-components';

import Theme, { deviceMediaQ } from '@src/theme';

import Button from '@components/button/button.component';
import ProductImage from '@components/product-image/product-image.component';
import SectionTitle from '@components/section-title/section-title.component';

import ProductModalStyled from './product-modal.styles';

import DownloadIcon from '@icons/download.svg';
import Carousel from '@components/carousel/carousel.component';

const Item = ({ children }) => (
  <p>- {children};</p>
);

const Dimensions = ({ dimensionsList, dimensions: { l, a, p } }) => {
  const hasLAP = l || a || p;

  if (!hasLAP && !dimensionsList) {
    return null;
  }

  return (
    <>
      <div className='section-title'>
        Dimensões
      </div>
      {hasLAP ? (
        <div className='lap'>
          {Object.entries({
            Altura: a,
            Comprimento: p,
            Largura: l,
          }).map(([name, value], idx) => {
            if (!value) {
              return null;
            }

            return (
              <div key={idx}>
                <p>{name}</p>
                <p>{value}</p>
              </div>
            );
          })}
        </div>
      ) : null}
      {(dimensionsList || []).map((el, idx) => <Item key={idx}>{el}</Item>)}
    </>
  );
};

const Accessories = (
  {
    accessories = null,
  },
  ) => {
    if (!accessories || !accessories.length) {
      return null;
    }

    return (
      <>
        <h3 className='section-title'>
          Acessórios
        </h3>
        {
          accessories.map((acc, idx) => (
            <Item key={idx}>{acc}</Item>
          ))
        }
      </>
    );
  }
;

const ProductModalContentComponent = ({ product }) => (
  <ProductModalStyled>
    <SectionTitle style={{ textAlign: 'center' }}>{product.slug.toUpperCase()}<br />{product.title}</SectionTitle>
    <div className='product-image'>
      {
        product.images.length > 1 ?
          <Carousel product={product} />
          : (
            <ProductImage product={product} objectFit='contain' className='image' />
          )
      }
    </div>
    <h3 className='section-title'>
      Informações técnicas
    </h3>
    {product.features.map((feature, idx) => (
      <Item key={idx}>{feature}</Item>
    ))}
    <Accessories accessories={product.accessories} />
    <Dimensions dimensions={product.dimensions} dimensionsList={product.dimensions_list} />
  </ProductModalStyled>
);

const FooterButton = styled(Button)`
  align-self: flex-end;
  width: 200px;
  font-weight: 700 !important;
`;

const FBtn = ({ url, text, product, fileName, ...props }) => {
  if (!url) {
    return null;
  }

  return (
    <a
      href={url}
      download={fileName}
      target='_blank'
    >
      <FooterButton
        primary
        icon={<DownloadIcon />}
        href={url}
        {...props}
      >
        {text.toUpperCase()}
      </FooterButton>
    </a>
  );
};

const FooterButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  
  @media ${deviceMediaQ.tablet} {
    flex-direction: column;
    margin-top: 20px;
    
    button {
      width: 100%;
    }
  }
`;

export const FooterButtonComponent = ({ product: { slug, datasheet_url = null, doc_url = null }, ...props }) => (
  <FooterButtons>
    <FBtn text={'manual'} url={datasheet_url} fileName={`manual-${slug}`} />
    <FBtn text={'mais detalhes'} url={doc_url} fileName={`detalhes-${slug}`} />
  </FooterButtons>
);

export default ProductModalContentComponent;
